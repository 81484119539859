<template>
  <div class="user-knowledgesets">
      <h2>My knowledgesets</h2>
      <div v-if="knowledgesets">
        <ListView :knowledgesets="knowledgesets" />
      </div>
      <router-lin :to="{ name: 'CreateKnowledgeset' }" class="btn">Create a new Knowledgeset</router-lin>
  </div>
</template>

<script>
import getUser from '@/composables/getUser'
import getCollection from '@/composables/getCollection'
import ListView from '@/components/ListView.vue'

export default {
  components: { ListView },
  setup() {
    const { user } = getUser()
    const { documents: knowledgesets } = getCollection(
        'knowledgesets', 
        ['userId', '==', user.value.uid]
        )

    console.log(knowledgesets)

    return { knowledgesets }
  }

}
</script>

<style>

</style>