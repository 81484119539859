import { ref } from 'vue'
import { projectStorage } from '../firebase/config'

const useStorage = () => {
    const error = ref(null)
    const url = ref(null)

    const uploadImage = async (file, filePath) => {
        if (filePath.length) {
            console.log('useStorage filePath: ', filePath)
            const storageRef = projectStorage.ref(filePath)
    
            try {
                const res = await storageRef.put(file)
                url.value = await res.ref.getDownloadURL()
            } catch(err) {
                console.log(err.message)
                error.value = err.message
            }
        } 
    }

    const deleteImage = async (path) => {
        const storageRef = projectStorage.ref(path)

        try {
            await storageRef.delete()
        } catch(err) {
            console.log(err.message)
            error.value = err.message
        }
    }

    return { url, error, uploadImage, deleteImage }
}

export default useStorage