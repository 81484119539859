<template>
  <!-- <draggable :list="knowledgesets" group="items" item-key="order" @change="log" > -->
      <ListViewItem v-for="knowledgeset in knowledgesets" :key="knowledgeset.id" :knowledgeset="knowledgeset">
      </ListViewItem>
  <!-- </draggable> -->
</template>

<script>
import draggable from 'vuedraggable'
import ListViewItem from './ListViewItem.vue'

export default {
    props: ['knowledgesets'],
    components: { draggable, ListViewItem },
    methods: {
      onEnd: (e) => {
        console.log(e)
      },
      log(e) {
        console.log(e)
      }
    }
}
</script>

<style scoped>
  .single {
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    background: white;
    margin: 16px 0;
    transition: all ease 0.2s;
  }
  .single:hover {
    box-shadow: 1px 2px 3px rgba(50,50,50,0.05);
    transform: scale(1.02);
    transition: all ease 0.2s;
  }
  .thumbnail {
    max-width: 160px;
    max-height: 80px;
    overflow: hidden;
    border-radius: 5px;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    display: block;
  }
  .info {
    margin: 0 30px;
  }
  .song-number {
    margin-left: auto;
  }
</style>