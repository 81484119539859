import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'

const getDocumentRef = (collection) => {

  const error = ref(null)
  const documentRef = ref(null)

  if (collection) {
      // register the firestore collection reference
      documentRef.value = projectFirestore.collection(collection).doc()
      error.value = null
  } else {
      error.value = 'invalid collection'
  }

  return { error, documentRef }
}

export default getDocumentRef