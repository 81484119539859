<template #item="{ knowledgeset }">
    <router-link :to="{ name: 'KnowledgesetDetails', params: { id: knowledgeset.id }}">
    <div class="single">
        <div class="thumbnail">
            <img :src="knowledgeset.thumbnailUrl" draggable=false>
        </div>
        <div class="info">
            <h3>{{ knowledgeset.title }}</h3>
            <p>Created by {{ knowledgeset.userName }}</p>
        </div>
    </div>
    </router-link>
</template>

<script>
export default {
    props: ['knowledgeset']

}
</script>

<style scoped>
  .single {
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    background: white;
    margin: 16px 0;
    transition: all ease 0.2s;
  }
  .single:hover {
    box-shadow: 1px 2px 3px rgba(50,50,50,0.05);
    transform: scale(1.02);
    transition: all ease 0.2s;
  }
  .thumbnail {
    max-width: 160px;
    max-height: 80px;
    overflow: hidden;
    border-radius: 5px;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    display: block;
  }
  .info {
    margin: 0 30px;
  }
  .song-number {
    margin-left: auto;
  }

</style>