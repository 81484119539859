<template>
  <div v-if="error" class="error">{{ error }}</div>
  <div v-if="knowledgeset" class="knowledgeset-details">

        <!-- konwledgeset information -->
        <div class="knowledgeset-info">
            <div class="thumbnail">
                <img :src="knowledgeset.thumbnailUrl">
            </div>
            <h2>{{ knowledgeset.title }}</h2>
            <p class="username">Created by {{ knowledgeset.userName }}</p>
            <p class="description">{{ knowledgeset.description }}</p>
            <button v-if="ownership" @click="handleDelete">Delete Knowledgeset</button>
        </div>

        <!-- stack list -->
        <div class="stack-list">
            <div v-if="!knowledgeset.decks.length">No decks have been added to this knowledgeset yet.</div>
            <div v-for="deck in knowledgeset.decks" :key="deck.id" class="single-deck">
                <div class="details">
                    <h3>{{ deck.title }}</h3>
                    <p>{{ deck.description }}</p>
                </div>
                <button v-if="ownership" @click="handleClick(deck.id)">Delete</button>
            </div>
            <AddDeck v-if="ownership" :knowledgeset="knowledgeset" />
        </div>
  </div>

</template>

<script>
import AddDeck from '@/components/AddDeck.vue'
import useStorage from '@/composables/useStorage'
import useDocument from '@/composables/useDocument'
import getDocument from '@/composables/getDocument'
import getUser from '@/composables/getUser'
import { computed } from '@vue/runtime-core'
import { useRouter } from 'vue-router'

export default {
    props: ['id'],
    components: { AddDeck },
    setup(props) {
        const { error, document: knowledgeset } = getDocument('knowledgesets', props.id)
        const { user } = getUser()
        const { deleteDoc, updateDoc } = useDocument('knowledgesets', props.id)
        const { deleteImage } = useStorage()
        const router = useRouter()

        const ownership = computed(() => {
            return knowledgeset.value && user.value && user.value.uid == knowledgeset.value.userId
        })

        const handleDelete = async () => {
            await deleteImage(knowledgeset.value.filePath)
            await deleteDoc()
            router.push({ name: 'Home' })
        }

        const handleClick = async (id) => {
            const decks = knowledgeset.value.decks.filter((deck) => deck.id != id)
            await updateDoc({ decks: decks })
        }

        return { error, knowledgeset, ownership, handleDelete, handleClick }
    }
}
</script>
<style scoped>
  .knowledgeset-details {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 80px;
  }
  .thumbnail {
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    padding: 90px;
    max-width: 150px;
    max-height: 90px;
  }
  .thumbnail img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
  }
  .knowledgeset-info {
    text-align: center;
  }
  .knowledgeset-info h2 {
    text-transform: capitalize;
    font-size: 28px;
    margin-top: 20px;
  }
  .knowledgeset-info p {
    margin-bottom: 20px;
  }
  .username {
    color: #999;
  }
  .description {
    text-align: left;
  }
  .single-deck {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed var(--secondary);
    margin-bottom: 20px;
  }
</style>