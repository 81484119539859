<template>
  <div class="add-deck">
      <button v-if="!showForm" @click="showForm = true">Add Decks</button>
      <form v-if="showForm" @submit.prevent="handleSubmit">
          <h4>Add a New Deck</h4>
          <input type="text" placeholder="Deck Title" required v-model="title">
          <input type="text" placeholder="Deck Description" required v-model="description">
          <button>Add</button>
      </form>
  </div>
</template>

<script>
import { ref } from 'vue'
import useDocument from '../composables/useDocument'

export default {
  props: ['knowledgeset'],
  setup(props) {
    const title = ref('')
    const description = ref('')
    const showForm = ref(false)
    const { updateDoc } = useDocument('knowledgesets', props.knowledgeset.id)
    const handleSubmit = async () => {
      const newDeck = {
        title: title.value,
        description: description.value,
        id: Math.floor(Math.random() * 1000000)
      }
      const res = await updateDoc({
        decks: [...props.knowledgeset.decks, newDeck]
      })
      title.value = ''
      description.value = ''
    }
    return { title, description, showForm, handleSubmit }
  }
}

</script>

<style scoped>
  .add-deck {
    text-align: center;
    margin-top: 40px;
  }
  form {
    max-width: 100%;
    text-align: left;
  }
</style>