<template>
  <form @submit.prevent="handleSubmit">
    <h4>Create New Knowledgeset</h4>
    <input type="text" required placeholder="Knowledgeset Title" v-model="title">
    <textarea required placeholder="Knowledgeset Description" v-model="description"></textarea>
    <!-- upload knowledgeset image -->
    <label>Upload Knowledgeset Wide Thumbnail (16x9 ratio)</label>
    <input type="file" @change="handleChange" required>
    <div class="error">{{ fileError }}</div>
    <button v-if="!isPending">Create</button>
    <button v-else disabled>Saving...</button>
  </form>
</template>

<script>
import useStorage from '@/composables/useStorage'
import useCollection from '@/composables/useCollection'
import getDocumentRef from '@/composables/getDocumentRef'
import getUser from '@/composables/getUser'
import { ref } from 'vue'
import { timestamp } from '@/firebase/config'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const { url, uploadImage } = useStorage()
    const { refError, documentRef } = getDocumentRef('knowledgesets')
    const { error, addDoc, newDoc } = useCollection('knowledgesets')
    const { user } = getUser()
    const router = useRouter()

    const title = ref('')
    const description = ref('')
    const file = ref(null)
    const fileError = ref(null)
    const isPending = ref(false)
    const filePath = ref(null)
    const fileExtension = ref(null)

    const handleSubmit = async () => {
      if (file.value) { 
        isPending.value = true
        // get a docid
        await getDocumentRef()

        // build a filePath
        fileExtension.value = file.value.name.split('.').pop();
        filePath.value = `images/knowledgesets/${documentRef.value.id}/thumbnail-wide.${fileExtension.value}`
        console.log('filePath: ', filePath.value)

        // add image to storage
        await uploadImage(file.value, filePath.value)

        // add knowledgeset to forestore
        await addDoc({
          title: title.value,
          description: description.value,
          userId: user.value.uid,
          userName: user.value.displayName,
          thumbnailUrl: url.value,
          filePath: filePath.value,
          decks: [],
          order: 0,
          createdAt: timestamp()
        }, documentRef.value)
        if (!error.value) {
          router.push({ name: 'KnowledgesetDetails', params: { id: documentRef.value.id}})
        }
      }
      isPending.value = false
    }

    // allowed file types
    const types = ['image/png', 'image/jpeg']

    const handleChange = (e) => {
      const selected = e.target.files[0]
      console.log(selected)

      if (selected && types.includes(selected.type)) {
        file.value = selected
        fileError.value = null
      } else {
        file.value = null
        fileError.value = 'Please select an image file (png or jpg)'
      }
      
    }

    return { title, description, handleSubmit, handleChange, fileError, isPending }
  }
}
</script>

<style>
  form {
    background: white;
  }
  input[type="file"] {
    border: 0;
    padding: 0;
  }
  label {
    font-size: 12px;
    display: block;
    margin-top: 30px;
  }
  button {
    margin-top: 20px;
  }
</style>