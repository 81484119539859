import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCgNfOKGOiyGhcm3lC6lx_eFZrlS-RO5F4",
    authDomain: "kno-dev.firebaseapp.com",
    databaseURL: "https://kno-dev.firebaseio.com",
    projectId: "kno-dev",
    storageBucket: "kno-dev.appspot.com",
    messagingSenderId: "482540995875",
    appId: "1:482540995875:web:001fb9950fb1cb4f4d7896",
    measurementId: "G-JNZWRGBNQH"
  };

  // init firebase
  firebase.initializeApp(firebaseConfig)

  // init services
  const projectFirestore = firebase.firestore()
  const projectAuth = firebase.auth()
  const projectStorage = firebase.storage()

  // timestamp
  const timestamp = firebase.firestore.FieldValue.serverTimestamp

  export { projectFirestore, projectAuth, projectStorage, timestamp }